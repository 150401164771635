<script lang="ts" setup>
import type { EditorialStripAnchorsProps } from '@design-system/components/Editorial/StripAnchors/EditorialStripAnchors.props'
import type { EditorialStripAnchorsItemProps } from '@design-system/components/Editorial/StripAnchors/EditorialStripAnchorsItem.props'
import type { UIMediaProps } from '@design-system/components/UI/UIMedia.props'
import type { EditorialStripAnchors } from '@integration-layer/ampliance/schemas/editorial-strip-anchors-schema.localized'

const props = defineProps<{
  deliveryId: string
  locale: string
  vse?: string
  content?: EditorialStripAnchors
}>()

const { data: componentResponse, execute } =
  await useFetch<EditorialStripAnchors>(
    `/api/cms/getByKey?locale=${props.locale}&id=${props.deliveryId}&type=key&vse=${props?.vse ?? ''}`,
    {
      getCachedData: (k, nuxtApp) => nuxtApp.payload.data[k],
      immediate: false,
    }
  )
if (!props.content && !componentResponse.value) {
  await execute()
}

const data = computed(() => props.content ?? componentResponse.value)

const stripItems: EditorialStripAnchorsItemProps[] =
  data.value?.contentStrip
    .filter(i => !!i)
    .map((item: any) => ({
      showInAllCountries: item.showInAllCountries,
      showInCountry: item.showInCountry,
      icon: item.icon,
      media: item?.media
        ? (transformMedia(item.media) as UIMediaProps)
        : undefined,
      link: item.link,
      textColor: item.textColor,
      sidesheetContent: {
        heading: item?.sidesheetContent?.heading,
        body: item?.sidesheetContent?.body,
      },
    })) ?? []

const transformedData = computed<EditorialStripAnchorsProps>(() => ({
  type: data.value?.type!,
  bgColorStrip: data.value?.bgColorStrip,
  contentStrip: stripItems,
}))
</script>

<template>
  <EditorialStripAnchors v-bind="transformedData" />
</template>
