<script setup lang="ts">
import type { EditorialStripAnchorsProps } from './EditorialStripAnchors.props'
import type { EditorialStripAnchorsItemProps } from './EditorialStripAnchorsItem.props'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const props = defineProps<EditorialStripAnchorsProps>()

const { openDialog, isDialogOpen } = useDialog(
  SIDEBAR_IDS.editorialStripAnchorsSidebar
)

const isAnimating = ref(true)

const startPauseAnimation = (action: string = '') => {
  if (action === 'leave' && isDialogOpen.value) return
  isAnimating.value = !isAnimating.value
}

watch(isDialogOpen, () => (isAnimating.value = !isDialogOpen.value))

const displayedItemsForLocale = computed(() => {
  return props.contentStrip.filter(stripItem => {
    return stripItem.showInCountry || stripItem.showInAllCountries
  })
})

const { dispatchViewPromotionEvent, dispatchSelectPromotionEvent } =
  useGAPromotionEvents()

const stripAnchorsList = ref(
  displayedItemsForLocale.value
    ? displayedItemsForLocale.value.map(message =>
        message.link.label ? message.link.label : null
      )
    : []
)

onMounted(() => {
  if (stripAnchorsList.value.length > 0) {
    dispatchViewPromotionEvent(stripAnchorsList?.value[0] ?? '', [])
  }
})

const currentSidesheetContent = ref<
  EditorialStripAnchorsItemProps['sidesheetContent'] | null
>(null)

const handleClick = (item: EditorialStripAnchorsItemProps) => {
  dispatchSelectPromotionEvent(
    'carousel_slider',
    item.link?.target ?? '',
    item.link.label ?? ''
  )
  if (item.link.type !== 'sidesheet') return
  currentSidesheetContent.value = item.sidesheetContent
  openDialog(SIDEBAR_IDS.editorialStripAnchorsSidebar)
}
</script>

<template>
  <div
    class="border-y-neutral-gray lg:py-4xl px-md py-xxl overflow-hidden border-y"
    :class="[
      bgColorStrip ? `bg-neutral-${bgColorStrip}` : 'bg-background-secondary',
    ]"
    @mouseenter="startPauseAnimation()"
    @mouseleave="startPauseAnimation('leave')"
  >
    <div class="-mx-3 flex flex-nowrap lg:-mx-6">
      <ul
        v-for="i in 2"
        :key="i"
        class="infinite-scroll gap-xxl px-md flex lg:min-w-full lg:justify-between"
        :style="[
          {
            'animation-play-state': `${isAnimating ? 'running' : 'paused'}`,
          },
        ]"
      >
        <li
          v-for="(stripItem, index) in displayedItemsForLocale"
          :key="`strip-item-${index}`"
          :style="{ '--total-items': displayedItemsForLocale.length }"
          class="dinamic-content-width"
        >
          <EditorialStripAnchorsItem
            v-bind="stripItem"
            :text-color="textColor"
            :type="type"
            @emit-item="handleClick(stripItem)"
          />
        </li>
      </ul>
    </div>
  </div>

  <OrganismsSidebarSlide
    v-if="currentSidesheetContent"
    :id="SIDEBAR_IDS.editorialStripAnchorsSidebar"
    :header-props="{
      titleText: currentSidesheetContent.heading,
    }"
  >
    <template #body>
      <UtilsMarkdown :content="currentSidesheetContent.body" is-preprocessed />
    </template>
  </OrganismsSidebarSlide>
</template>

<style scoped lang="scss">
.infinite-scroll {
  @apply transform-gpu;
  animation: scroll-animation 60s linear infinite;
  will-change: transform;
}

@keyframes scroll-animation {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(-100%);
  }
}

.dinamic-content-width {
  @screen lg {
    width: calc(100% / var(--total-items));
  }
}
</style>
