import { viewPromotionSchema } from '@integration-layer/schemas/GAEvents/viewPromotion'
import { selectPromotionSchema } from '@integration-layer/schemas/GAEvents/selectPromotion'
import snakeCase from 'lodash/snakeCase'

export const useGAPromotionEvents = () => {
  const debug = !!useRuntimeConfig().public.ga4.debug

  const dispatchViewPromotionEvent = (promotionId: string, items: string[]) =>
    useGASendEvent(
      viewPromotionSchema,
      {
        event: 'view_promotion',
        eventID: '015',
        ecommerce: {
          creative_name: 'EditorialStripAnchors',
          creative_slot: '-',
          promotion_id: snakeCase(`strip-anchors-${promotionId}`),
          promotion_name: promotionId ?? '',
          items,
        },
      },
      {
        clearEcommerce: true,
        debug,
      }
    )
  const dispatchSelectPromotionEvent = (
    promotionLocation: string,
    selectedPromotionId: string,
    selectedPromotion: string
  ) => {
    useGASendEvent(
      selectPromotionSchema,
      {
        event: 'select_promotion',
        eventID: '016',
        ecommerce: {
          creative_name: promotionLocation ?? '',
          creative_slot: '-',
          promotion_id: selectedPromotionId ?? '',
          promotion_name: selectedPromotion ?? '',
          items: [],
        },
      },
      {
        clearEcommerce: true,
        debug,
      }
    )
  }
  return {
    dispatchViewPromotionEvent,
    dispatchSelectPromotionEvent,
  }
}
