<script setup lang="ts">
import type { EditorialStripAnchorsProps } from './EditorialStripAnchors.props'
import EditorialStripAnchorsAlternative from './EditorialStripAnchorsAlternative.vue'
import EditorialStripAnchorsDefault from './EditorialStripAnchorsDefault.vue'

defineProps<EditorialStripAnchorsProps>()

const stripAnchorsMapping: Record<string, Component> = {
  default: EditorialStripAnchorsDefault,
  alternative: EditorialStripAnchorsAlternative,
}
</script>

<template>
  <component
    :is="stripAnchorsMapping[type]"
    :content-strip="contentStrip"
    :type="type"
    :bg-color-strip="bgColorStrip"
    :text-color="textColor"
  />
</template>
