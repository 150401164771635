import { z } from 'zod'

export const viewPromotionSchema = z.object({
  event: z.string(),
  eventID: z.string(),
  ecommerce: z.object({
    creative_name: z.string(),
    creative_slot: z.literal('-'),
    promotion_id: z.string(),
    promotion_name: z.string(),
    items: z.array(z.string()),
  }),
})
