<script setup lang="ts">
import type { EditorialStripAnchorsProps } from './EditorialStripAnchors.props'
import type { EditorialStripAnchorsItemProps } from './EditorialStripAnchorsItem.props'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const props = defineProps<EditorialStripAnchorsProps>()

const { dispatchViewPromotionEvent, dispatchSelectPromotionEvent } =
  useGAPromotionEvents()

const stripAnchorsList = ref(
  props.contentStrip
    ? props.contentStrip.map(message =>
        message.link.label ? message.link.label : null
      )
    : []
)

onMounted(() => {
  if (stripAnchorsList.value.length > 0) {
    dispatchViewPromotionEvent(stripAnchorsList?.value[0] ?? '', [])
  }
})

const { openDialog } = useDialog(SIDEBAR_IDS.editorialStripAnchorsSidebar)

const currentSidesheetContent = ref<
  EditorialStripAnchorsItemProps['sidesheetContent'] | null
>(null)

const handleClick = (item: EditorialStripAnchorsItemProps) => {
  dispatchSelectPromotionEvent(
    'carousel_slider',
    item.link?.target ?? '',
    item.link.label ?? ''
  )
  if (item.link.type !== 'sidesheet') return
  currentSidesheetContent.value = item.sidesheetContent
  openDialog(SIDEBAR_IDS.editorialStripAnchorsSidebar)
}
</script>

<template>
  <div
    class="bg-background-secondary border-y-neutral-gray lg:py-4xl px-md py-xxl border-y"
    :class="[
      bgColorStrip ? `bg-neutral-${bgColorStrip}` : 'bg-background-secondary',
    ]"
  >
    <ul class="gap-xxl grid justify-items-center sm:grid-cols-2 md:grid-cols-4">
      <li
        v-for="(stripItem, index) in contentStrip"
        :key="`${stripItem.link.label}-${index}`"
        class="flex w-full flex-col"
      >
        <EditorialStripAnchorsItem
          v-bind="stripItem"
          :text-color="textColor"
          :type="type"
          @emit-item="handleClick(stripItem)"
        />
      </li>
    </ul>
  </div>

  <OrganismsSidebarSlide
    v-if="currentSidesheetContent"
    :id="SIDEBAR_IDS.editorialStripAnchorsSidebar"
    :header-props="{
      titleText: currentSidesheetContent.heading,
    }"
  >
    <template #body>
      <UtilsMarkdown :content="currentSidesheetContent.body" is-preprocessed />
    </template>
  </OrganismsSidebarSlide>
</template>
